<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>

      <h3>{{ $t('DIAS.DETAILS') }} </h3>

      <InputField :field-title="$t('DIAS.TITLE')" :field-name="'title'" :rules="'required'" :type="'text'"
        :placeholder="$t('DIAS.TITLE_PLACEHOLDER')" :value="initialData ? initialData.title : ''"
        class="form-element" />
      <RichTextField :field-title="$t('DIAS.DESCRIPTION')" :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
        :input-callback="descriptionInput" :value="initialData ? initialData.description : ''" />

      <Dropdown id="group" :field-title="$t('DIAS.TYPE_ANSWER')" :dropdown-options="types" :placeholder="''"
        :field-name="'group'" :selected-option="selectedType" :callback="(value) => selectType(value)"
        :api-error-text="''" :disabled="initialData !== null" class="form-element" />

      <div v-for="(option, index) in options" :key="index">
        <InputField v-if="selectedType.id == 0" :field-title="$t('DIAS.ANSWER') + ' ' + (index + 1)"
          :field-name="'answer' + index" :rules="'required'" :value="option.text" :type="'text'"
          :on-update="(text) => updateText(index, text)" :placeholder="$t('DIAS.ANSWER_PLACEHOLDER')"
          :max-count="maxCharacters" class="form-element" />
        <h4>{{ $t('DIAS.IMAGE') }}&nbsp;
          {{ index + 1 }}&nbsp;
          <span class="optional">{{ (selectedType.id == 0 ? ' (' + $t('OPTIONAL') + ')' : '') }}</span>
        </h4>
        <ImageInput :start-file="option.image" :media-files="initialData ? initialData.mediaFiles : null"
          @file="(image) => updateImage(index, image)" />

        <SelectVideo :title="$t('DIAS.VIDEO_RESULT')" :initial-selection="option.video"
          @selected="(video) => updateVideo(index, video)" />
        <div class="border-line" />
      </div>

      <InputField :field-title="$t('DIAS.BITS')" field-name="bitsAwarded" rules="required" type="number"
        :value="initialData ? initialData.bitsAwarded : ''" :placeholder="$t('DIAS.BITS_PLACEHOLDER')"
        cy-selector="bits-input" class="form-element" :input-icon="bitsIcon" />
    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button">
        {{ $t('SAVE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { CREATE_DIA } from '@/store/modules/cms/missions/actions'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField.vue'
import nl from '@/utils/language/nl.json'
import Dropdown from '@/components/yo-form/Dropdown.vue'
import ImageInput from '../../../elements/inputFields/ImageInput.vue'
import SelectVideo from '@/components/elements/inputFields/SelectVideo'
import bitsIcon from '@/assets/icons/icn_bits_transparent.svg'

export default {
  name: 'DiaFormDilemma',
  components: {
    InputField,
    FormWrapper,
    RichTextField,
    Dropdown,
    SelectVideo,
    ImageInput,
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    initialData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    const slideId = this.initialData ? this.initialData.id : null
    const types = [
      {
        id: 0,
        name: nl.DIAS.DILEMNA.TYPES.TEXT
      }, {
        id: 1,
        name: nl.DIAS.DILEMNA.TYPES.PICTURE
      },
    ]
    const selectedType = this.initialData ? (this.initialData.contentQuestionJson.answers[0].text ? types[0] : types[1]) : types[0]
    const defaultOption = {
      text: '',
      image: null,
      video: null
    }
    const options = this.initialData ? this.mapInitialData() : [{ ...defaultOption }, { ...defaultOption }]
    return {
      types,
      selectedType,
      options,
      slideId,
      description: '',
      maxCharacters: 50,
      bitsIcon
    }
  },
  methods: {
    mapInitialData() {
      const answers = this.initialData.contentQuestionJson.answers
      const options = answers.map(answer => {
        if (!answer.resultVideoId.videoUrl && answer.resultVideoId.originalVideoUrl) {
          answer.resultVideoId.videoUrl = answer.resultVideoId.originalVideoUrl
        }

        return {
          text: answer.text,
          image: answer.answerPictureUrl,
          video: answer.resultVideoId
        }
      })
      return options
    },
    getCorrectPicture(index) {
      return this.initialData.answerPictures[0]
    },
    selectType(choice) {
      this.selectedType = choice
    },
    descriptionInput(value) {
      this.description = value
    },
    updateText(index, text) {
      this.options[index].text = text
    },
    updateImage(index, image) {
      this.options[index].image = image
    },
    updateVideo(index, video) {
      this.options[index].video = video
    },
    validateData(data) {
      const answers = this.options.map(option => {
        return {
          text: option.text,
          hasAnswerPicture: option.image !== null,
          resultVideoId: option.video
        }
      })
      const pictures = this.options.filter(option => option.image).map(x => x.image)
      data.description = this.description
      data.missionId = this.id
      data.answers = answers
      data.answerPictures = pictures
      const typeString = this.selectedType.id === 0 ? 'dilemma-text' : 'dilemma-picture'
      this.$store.dispatch(CREATE_DIA, { data, type: typeString, slideId: this.slideId })
        .then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          } else {
            console.log(response)
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.border-line {
  border-top: 1px solid #DDE3F0;
  margin-bottom: rem(20);
  margin-top: rem(20);
  width: 100%;
}

h3 {
  margin-bottom: rem(20);
}

h4 {
  color: var(--blue_dark);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-top: rem(20);

  .optional {
    color: #A5A5A5;
    font-weight: 400;
  }
}
</style>

<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>

      <h3>Drag & Drop {{ $t('DIA') }}</h3>

      <InputField :field-title="$t('DIAS.TITLE')" :field-name="'title'" :rules="'required'" :type="'text'"
        :placeholder="$t('DIAS.TITLE_PLACEHOLDER')" :cy-selector="'name-input'"
        :value="initialData ? initialData.title : ''" class="form-element" />
      <RichTextField :field-title="$t('DIAS.DESCRIPTION')" :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
        :input-callback="descriptionInput" :value="initialData ? initialData.description : ''"
        :error-text="textInputError" />
      <LargeInputField :field-title="$t('DIAS.TIP')" :field-name="'tip'" :inline-subtitle="`(${$t('OPTIONAL')})`"
        :type="'text'" :rules="'optional'" :placeholder="$t('DIAS.TIP_PLACEHOLDER')" :cy-selector="'name-input'"
        :value="initialData ? initialData.tip : null" class="form-element" />
      <DragAndDropInput ref="dragAndDropInput" :on-update="updateDragAndDropContent" :field-title="$t('DIAS.TEXT')"
        :initial-data="initialData" />

      <InputField :field-title="$t('DIAS.BITS')" field-name="bitsAwarded" rules="required" type="number"
        :value="initialData ? initialData.bitsAwarded : ''" :placeholder="$t('DIAS.BITS_PLACEHOLDER')"
        cy-selector="bits-input" class="form-element" :input-icon="bitsIcon" />

      <div class="border-line" />

      <FeedbackInput :initial-data="initialData" @data="feedbackData" />
    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button">
        {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import InputField from '@/components/yo-form/InputField'
import LargeInputField from '@/components/yo-form/LargeInputField'

import FormWrapper from '@/components/yo-form/FormWrapper'
import { CREATE_DIA } from '@/store/modules/cms/missions/actions'
import DragAndDropInput from '@/components/elements/missions/DragAndDropInput'
import FeedbackInput from '@/components/partials/missions/FeedbackInput'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import bitsIcon from '@/assets/icons/icn_bits_transparent.svg'

export default {
  name: 'Details',
  components: {
    InputField,
    FormWrapper,
    FeedbackInput,
    DragAndDropInput,
    LargeInputField,
    RichTextField
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    initialData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    const slideId = this.initialData ? this.initialData.id : null

    return {
      answer: [],
      correctAnswer: [],
      question: [],
      feedback: {},
      slideId,
      descriptionValue: this.initialData ? this.initialData.description : '',
      bitsIcon
    }
  },
  methods: {
    descriptionInput(data) {
      this.descriptionValue = data
    },
    updateDragAndDropContent(currentText, stopWords, trickWords) {
      // eslint-disable-next-line no-useless-escape
      const regexBold = new RegExp('<b>.*?<\/b>')
      const paragraphSplit = currentText.split(regexBold)

      for (let i = paragraphSplit.length - 1; i > 0; i--) {
        paragraphSplit.splice(i, 0, null)
      }

      this.answer = stopWords.map(x => x.name).concat(trickWords.map(x => x.name))
      this.correctAnswer = [...Array(stopWords.length).keys()]
      this.question = paragraphSplit.filter(String)
    },
    feedbackData(data) {
      this.feedback = data
    },
    validateData(data) {
      if (!this.$refs.dragAndDropInput.verify()) {
        return
      }
      if (this.feedback.correctVideo) {
        data.correctVideoId = this.feedback.correctVideo.id
      }
      if (this.feedback.wrongVideo) {
        data.incorrectVideoId = this.feedback.wrongVideo.id
      }

      data.missionId = this.id
      data.description = this.descriptionValue
      data.question = this.question
      data.answers = this.answer
      data.correctAnswer = this.correctAnswer
      data.correctVideoId =
        this.$store.dispatch(CREATE_DIA, { data, type: 'drag-and-drop', slideId: this.slideId }).then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          } else {
            console.log(response)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.border-line {
  border-top: 2px solid #DDE3F0;
  margin-bottom: rem(20);
  margin-top: rem(20);
}

.form-element {
  margin-top: rem(20);

  h3 {
    color: #373B52;
  }

  .form-input {
    padding: rem(10) rem(10) rem(10) rem(10);
  }
}

h3 {
  margin-bottom: rem(20);
}
</style>
